import React from "react";
import { bool, node, object, oneOf, string } from "prop-types";
import cx from "classnames";

import "./section.scss";
import "../../scss/global.scss";

import Container100 from "../Container/Container100.jsx";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";

const propTypes = {
  aos: bool, // https://github.com/michalsnik/aos
  children: node,
  className: string,
  link: object, // <ContentfulLink> will validate propTypes
  subtitle: node,
  theme: oneOf(["light", "dark"]),
  title: node,
  modifier: string,
  containerModifier: string,
};

const defaultProps = {
  theme: "light",
  modifier: "",
};

const Section = React.forwardRef((props, ref) => {
  const {
    aos,
    children,
    className,
    link,
    subtitle,
    title,
    theme,
    modifier,
    containerModifier,
    ...otherProps
  } = props;

  return (
    <section
      className={cx(
        "section",
        className,
        modifier && modifier,
        theme && `section--${theme}`,
      )}
      ref={ref}
      {...otherProps}
    >
      <div className={cx("section__container", containerModifier)}>
        {(title || subtitle || link) && (
          <Container100>
            <div className="section__titles" data-aos={aos && "fade-up"}>
              {title && (
                <div className="section__title fancy-bold">{title}</div>
              )}
              {(subtitle || link) && (
                <div className="section__subtitle">
                  {subtitle}{" "}
                  {link && (
                    <ContentfulLink className="section__link btn" {...link} />
                  )}
                </div>
              )}
            </div>
          </Container100>
        )}
        {children}
      </div>
    </section>
  );
});

Section.defaultProps = defaultProps;
Section.propTypes = propTypes;
export default Section;
